
//demo
const BASE_URL = "https://tamer.lmsadmin.iossmlm.com/lms_api/"

//local
// const BASE_URL = "http://192.168.21.19:5050/lms_api/"
// const BASE_URL = "https://0bf3-103-103-174-106.ngrok-free.app/lms_api/"


const IMG_URL = ""

export  { BASE_URL, IMG_URL }